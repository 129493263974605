var photoswipeInit = function( ) {

  var pswpElement = document.querySelectorAll('.pswp')[0];
  var items_inside = [
      {
          src: '../../css/img/inside/screenshots/INSIDE_01.jpg',
          w: 1920,
          h: 1080
      },
      {
          src: '../../css/img/inside/screenshots/INSIDE_02.jpg',
          w: 1920,
          h: 1080
      },
      {
          src: '../../css/img/inside/screenshots/INSIDE_03.jpg',
          w: 1920,
          h: 1080
      },
      {
          src: '../../css/img/inside/screenshots/INSIDE_04.jpg',
          w: 1920,
          h: 1080
      },
      {
          src: '../../css/img/inside/screenshots/INSIDE_05.jpg',
          w: 1920,
          h: 1080
      },
      {
          src: '../../css/img/inside/screenshots/INSIDE_06.jpg',
          w: 1920,
          h: 1080
      },
      {
          src: '../../css/img/inside/screenshots/INSIDE_07.jpg',
          w: 1920,
          h: 1080
      },
      {
          src: '../../css/img/inside/screenshots/INSIDE_08.jpg',
          w: 1920,
          h: 1080
      },
  ];

  var items_limbo = [
      {
          src: '../../css/img/limbo/screenshots/LIMBO_01.jpg',
          w: 1920,
          h: 1080
      },
      {
          src: '../../css/img/limbo/screenshots/LIMBO_02.jpg',
          w: 1920,
          h: 1080
      },
      {
          src: '../../css/img/limbo/screenshots/LIMBO_03.jpg',
          w: 1920,
          h: 1080
      },
      {
          src: '../../css/img/limbo/screenshots/LIMBO_04.jpg',
          w: 1920,
          h: 1080
      },
      {
          src: '../../css/img/limbo/screenshots/LIMBO_05.jpg',
          w: 1920,
          h: 1080
      },
      {
          src: '../../css/img/limbo/screenshots/LIMBO_06.jpg',
          w: 1920,
          h: 1080
      },
      {
          src: '../../css/img/limbo/screenshots/LIMBO_07.jpg',
          w: 1920,
          h: 1080
      },
      {
          src: '../../css/img/limbo/screenshots/LIMBO_08.jpg',
          w: 1920,
          h: 1080
      },
  ];

  var options = {
    index: 0,
    loop: true,
    closeOnScroll: false,
  };

  $('.thumbs-inside').click(function(){
    var dataInit = $( this ).attr('data-init');
    options.index = parseInt( dataInit );

    var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items_inside, options);
    gallery.init();
  });

  $('.thumbs-limbo').click(function(){
    var dataInit = $( this ).attr('data-init');
    options.index = parseInt( dataInit );

    var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items_limbo, options);
    gallery.init();
  });

}
